@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Poppins', sans-serif;
    line-height: 20px;
  }
}

.final-gradient {
    background: linear-gradient(97.69deg, #1D85FF -2.6%, #8525FF 95.54%);
}

.left-gradient {
  background: linear-gradient(97.69deg, #1D85FF -2.1%, #1D85FF -2.6%);
}

.right-gradient {
  background: linear-gradient(97.69deg, #8525FF 95.54%, #8525FF 95.7%);
}

.final-gradient-outline {
    background: linear-gradient(180deg, #257EFF 0%, #8526FF 100%);
}

.text-color-final-gradient {
  background: linear-gradient(97.69deg, #1D85FF -2.6%, #8525FF 95.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.green-gradient {
  background: linear-gradient(97.69deg, #10E3A4FF 0%, #10E3A4A0 100%);
}

body {
  font-size: 14px;
  min-width: 100vw;
  color: #fff;
  background-color: #1b1f26;
}

input {
  appearance: textfield;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:invalid {
  box-shadow: none;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	font-size: 100%;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.tooltip-mw-120 {
  max-width: 240px;
}